import React, { Fragment } from "react";
import { Message } from "semantic-ui-react";
import moment from "moment";
import "moment/locale/fr";
import logo from "./branding/logo.png";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import InvoicesWidget from "./InvoicesWidget";
import { platformName, companyName, paymentLabels } from "./branding/config";

import {
  Container,
  Divider,
  Dropdown,
  Card,
  Label,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Form,
  Button
} from "semantic-ui-react";

const IN_MAINTENANCE = false;

const PaymentLabels = () => {
  const elems = [];

  paymentLabels.forEach((label, i) => {
    if (elems.length > 0) {
      if (i === paymentLabels.length - 1) {
        elems.push(<span key={"sep_" + i}>{" ou "}</span>);
      } else {
        elems.push(<span key={"sep_" + i}>{", "}</span>);
      }
    }

    elems.push(<Label key={label}>{label}</Label>);
  });

  return <Fragment>{elems}</Fragment>;
};

const FixedMenuLayout = () => (
  <div>
    {/*
    <Menu fixed="top" inverted>
      <Container>
        <Menu.Item as="a" header>
          <Image
            size="mini"
            circular
            src={logo}
            style={{
              padding: "3px",
              marginRight: "1.5em",
              backgroundColor: "white"
            }}
          />
          Editions Spinola
        </Menu.Item>
      </Container>
    </Menu>*/}

    <Container
      text
      style={{
        marginTop: "1em"
        /* marginTop: "7em"  */
      }}
    >
      <Image size="tiny" style={{ borderRadius: "5%" }} centered src={logo} />
      {/*<Header as="h1">Editions Spinola</Header>*/}
      <Header as="h2">{platformName} - Centre de facturation</Header>
      <Header as="h3">Qu'est ce que {platformName}?</Header>
      <p>
        {platformName + " "}est un Centre de Formation en ligne pour les
        entrepreneurs. Nous disposons de plusieurs programmes de formation ayant
        pour objet d'accompagner nos élèves vers le succès dans leur démarche de
        vente en ligne, d'investissement, ou de passage à grande échelle de leur
        projet.
      </p>
      <Header as="h3">
        A quoi correspondent les débits labelisés <PaymentLabels /> sur mon
        compte bancaire?
      </Header>
      <p>
        Il s'agit très certainement de prélèvements bancaires liés à votre
        inscription à l'une de nos formations. Nous proposons certaines
        formations sous la forme d'achats uniques et d'autres sous la forme
        d'abonnements.
      </p>

      <Header as="h3">
        Comment retrouver les factures liés à ces prélèvements?
      </Header>
      <p>
        Vous pouvez retrouver vos factures à tout moment en utilisant l'outil
        ci-dessous:
      </p>

      {IN_MAINTENANCE ? (
        <Message negative>
          <Message.Header>Service en cours de maintenance</Message.Header>
          <p>
            Ce service est en cours de maintenance. <br />
            Nous sommes en train de lister de nouvelles factures.
          </p>
          <p>
            <strong>
              Retrouvez vos factures ici à partir de demain (
              {moment()
                .locale("fr")
                .add(1, "days")
                .format("LL")}
              )
            </strong>
          </p>
          <p>Veuillez nous excuser du dérangement!</p>
        </Message>
      ) : (
        <InvoicesWidget />
      )}
    </Container>

    <Segment
      inverted
      vertical
      style={{ margin: "5em 0em 0em", padding: "5em 0em" }}
    >
      <Container textAlign="center">
        {/*<Image
          centered
          size="mini"
          src={logo}
          circular
          style={{ backgroundColor: "white", padding: "3px" }}
        />*/}
        <List horizontal inverted divided link size="small">
          {/* <List.Item as="a" href="https://www.editions-spinola.com/">
            Site principal
          </List.Item>

          <List.Item
            as="a"
            href="https://www.editions-spinola.com/home-presentation"
          >
            A propos
          </List.Item>
          <List.Item
            as="a"
            href="https://www.editions-spinola.com/mentions-legales"
          >
            Mentions légales
          </List.Item>
          <List.Item as="a" href="https://spinolabayknowledge.com/acces">
            Espace client
          </List.Item>

          */}
          <List.Item as="a" href="mailto:support@se-former.app">
            Contact
          </List.Item>
        </List>
        <div>
          Copyright {moment().format("YYYY")} - {companyName} | Tous droits
          réservés
        </div>
      </Container>
    </Segment>
  </div>
);

function App() {
  return (
    <FixedMenuLayout />

    /*
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Hello <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
    */
  );
}

export default App;
