import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Init Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCrUEcRz2SzAEiYZ3w9E3e4ila3WoUqsi8",
  authDomain: "se-former-invoices-portal.firebaseapp.com",
  databaseURL: "https://se-former-invoices-portal.firebaseio.com",
  projectId: "se-former-invoices-portal",
  storageBucket: "se-former-invoices-portal.appspot.com",
  messagingSenderId: "948856014323",
  appId: "1:948856014323:web:a34f4f178f28bdcf30be86"
};
firebase.initializeApp(firebaseConfig);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
