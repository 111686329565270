import React, { Component } from "react";
import validator from "validator";
import firebase from "firebase";

const withAuthentication = WrappedComponent =>
  class Authentication extends Component {
    constructor(...args) {
      super(...args);

      // The component's Local state.
      this.state = {
        loading: true,
        signedIn: false,
        user: null,
        savedEmail: null,
        signInLink: null
      };

      // Bindings
      this.onAuthStateChange = this.onAuthStateChange.bind(this);
      this.requestEmailSignInLink = this.requestEmailSignInLink.bind(this);
      this.logOut = this.logOut.bind(this);
    }

    componentDidMount() {
      // On mount, start listening to authChanged events
      this.unregisterAuthObserver = firebase
        .auth()
        .onAuthStateChanged(this.onAuthStateChange);

      // Try to find e-mail and signinLink
      const savedEmail = this.retrieveSavedEmail();
      const signInLink = firebase
        .auth()
        .isSignInWithEmailLink(window.location.href)
        ? window.location.href
        : null;

      // Update the state and attempt to login in case the values are good
      this.setState({ savedEmail, signInLink }, () =>
        this.attemptLogin().catch(console.error)
      );
    }

    // Make sure we un-register Firebase observers when the component unmounts.
    componentWillUnmount() {
      this.unregisterAuthObserver();
    }

    // Reacts to changes in authentication state
    onAuthStateChange(userData) {
      const { signInLink } = this.state;

      // If user is set: register her or update her data
      let user = null;

      if (userData && userData.uid) {
        user = userData;

        // Save e-mail for later use
        this.saveEmail(userData.email);
      }

      // Change state
      this.setState({
        loading: false,
        signedIn: !!user,
        user,
        signInLink: user ? null : signInLink // Reset sign-in link if login is successful
      });

      // Send update as a console event
      // It'll be read by the Adok Meetings client app if it is the one requesting the page
      console.log(
        JSON.stringify({
          event: "authentication-update",
          data: {
            isSignedIn: !!user,
            userData: user || null
          }
        })
      );
    }

    // Store e-mail for later use
    saveEmail(email) {
      return new Promise(resolve => {
        window.localStorage.emailForSignIn = email;
        this.setState(
          {
            savedEmail: email
          },
          resolve
        );
      });
    }

    // Retrieve previously saved e-mail if any
    retrieveSavedEmail() {
      return window.localStorage.getItem("emailForSignIn") || null;
    }

    async requestEmailSignInLink(email) {
      // Return error if e-mail is invalid
      if (!email || !validator.isEmail(email)) {
        throw new Error("Invalid email");
      }

      // If e-mail is set, save it for later use
      const cleanEmail = email.trim().toLowerCase();
      await this.saveEmail(cleanEmail);

      // Attempt to login in case we're already on a sign in link but user
      // didn't retrieve the saved email properly
      const loggedIn = await this.attemptLogin();
      if (loggedIn) {
        return;
      }

      // Finally, ask Firebase to send the signIn link by e-mail
      await firebase.auth().sendSignInLinkToEmail(email, {
        url: window.location.href, // URL must be whitelisted in the Firebase Console.
        handleCodeInApp: true
      });
    }

    // Attempts to sign in using signInLink and e-mail
    async attemptLogin() {
      const { savedEmail, signInLink, signedIn } = this.state;
      if (signedIn) {
        return false;
      }

      if (!savedEmail || !signInLink) {
        return false;
      }

      let success = false;
      try {
        success = await firebase
          .auth()
          .signInWithEmailLink(savedEmail, signInLink);
      } catch (err) {
        console.error(err);
      }

      return success;
    }

    async logOut() {
      await this.saveEmail(null);
      return firebase.auth().signOut();
    }

    render() {
      const { loading, user, savedEmail: prefilledEmail } = this.state;

      const { requestEmailSignInLink, logOut } = this;

      return (
        <WrappedComponent
          {...{
            loading,
            user,
            prefilledEmail,
            requestEmailSignInLink,
            logOut
          }}
          {...this.props}
        />
      );
    }
  };

export default withAuthentication;
