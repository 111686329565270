import React, { Component, Fragment } from "react";
import check from "check-types";
import arrayFlatten from "array-flatten";
import request from "request-promise";
import moment from "moment";
import firebase from "firebase";
import ucfirst from "ucfirst";
import {
  Container,
  Divider,
  Dropdown,
  Card,
  Label,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Form,
  Button,
  Message
} from "semantic-ui-react";
import isEmailValid from "@seafront/is-email-valid";

import withAuthentication from "./withAuthentication";

import subscriptionIcon from "./assets/np_subscription_456173_A0A0A0.svg";
import invoiceIconBlue from "./assets/np_invoices_2064117_0E6EB8.svg";
import invoiceIconRed from "./assets/np_invoices_2064117_B03060.svg";

moment.locale("fr");

const URI =
  "https://us-central1-se-former-invoices-portal.cloudfunctions.net/api";

const SearchForm = ({
  onSubmit = () => {},
  inputRef = null,
  value = null,
  disabled = false,
  loading = false
}) => (
  <Card.Content>
    <Card.Header>Retrouver mes factures</Card.Header>
    <Card.Description>
      <Form onSubmit={onSubmit} loading={loading}>
        <Form.Field>
          <label>E-mail</label>
          <input
            placeholder="E-mail"
            type="email"
            ref={inputRef}
            defaultValue={value}
          />
        </Form.Field>
        {disabled ? (
          <Message>
            <Message.Header>Rendez-vous dans votre boîte e-mail</Message.Header>
            <p>
              Cliquez sur le lien qui vient de vous être envoyé par e-mail pour
              voir vos factures.
            </p>
          </Message>
        ) : (
          <Button color="teal" type="submit" disabled={loading}>
            Retrouver mes factures
          </Button>
        )}
      </Form>
    </Card.Description>
  </Card.Content>
);

class InvoicesWidget extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      email: null,
      response: null
    };
    this.emailField = React.createRef();

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    // this.setState({ email: localStorage["spinola-invoices.email"] });
  }

  async setStateAsync(state) {
    return new Promise(resolve => {
      this.setState(state, resolve);
    });
  }

  async onSubmit(...args) {
    const email = this.emailField.current.value.trim().toLowerCase();
    /*
    const email = this.emailField.current.value;
    localStorage["spinola-invoices.email"] = email;
    this.setState({ email: email.trim().toLowerCase(), response: null });
    */
    const {
      loading,
      user = null,
      prefilledEmail,
      requestEmailSignInLink = () => {},
      logOut = () => {}
    } = this.props;

    // If e-mail is not valid, show error
    if (!isEmailValid(email)) {
      await this.setStateAsync({
        error: "Cette adresse e-mail n'a pas l'air valide",
        response: null
      });
      return;
    }

    // If e-mail hasn't changed, just re-fetch invoices
    if (user && user.email === email) {
      await this.setStateAsync({ response: null, error: null });
      return this.fetchInvoices(user);
    }

    // Else, logout existing user if any
    if (user) {
      await logOut();
    }

    // Then attempts logging in with the e-mail privided
    await this.setStateAsync({
      signInRequested: true,
      response: null,
      error: null
    });
    await requestEmailSignInLink(email);
  }

  async componentWillUpdate(nextProps, nextState) {
    if (!this.props.user && !!nextProps.user && !!nextProps.user.email) {
      await this.fetchInvoices(nextProps.user);
    }
  }

  async fetchInvoices(user) {
    const { email } = user;
    const authToken = await firebase.auth().currentUser.getIdToken(true);

    try {
      const response = await request({
        uri: URI,
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        qs: {
          email
        },
        json: true // Automatically parses the JSON string in the response
      });

      this.setState({ response });
    } catch (err) {
      this.setState({
        response: "Error processing your request: " + err.message
      });
    }
  }

  render() {
    const { loading, user = null, prefilledEmail } = this.props;

    const {
      response = null,
      error = null,
      signInRequested = false
    } = this.state;
    const email = prefilledEmail;
    let content = [];

    const MessageDiv = ({ title, msg }) => (
      <Message negative>
        <Message.Header>{title}</Message.Header>
        <p>
          {/*Avez-vous une autre adresse e-mail?*/}
          {msg}
        </p>
      </Message>
    );

    content.push(
      <SearchForm
        key="form"
        loading={loading || (user && !response)}
        onSubmit={this.onSubmit}
        inputRef={this.emailField}
        value={email}
        disabled={signInRequested}
      />
    );

    if (error) {
      content.push(<Divider key="divider" />);
      content.push(<MessageDiv title="Erreur" msg={error} />);
    } else if (response) {
      content.push(<Divider key="divider" />);

      if (check.array(response)) {
        if (response.success === false) {
          content.push(
            <MessageDiv
              title="Erreur serveur"
              msg="Une erreur est apparue sur notre serveur. Nous avons été mis au courant. Merci de réessayer plus tard."
            />
          );
        } /* else if (!response.customerFound) {
          content.push(
            <MessageDiv
              title={`Aucun client trouvé avec l'e-mail '${email}'`}
              msg="Avez-vous une autre adresse e-mail?"
            />
          );
        } */ else if (
          !check.nonEmptyArray(response)
        ) {
          content.push(
            <MessageDiv
              title={`Aucune facture trouvée pour l'e-mail '${email}'`}
              msg="Vous avez bien créé un compte chez nous avec cet e-mail, mais vous n'avez jamais été facturé. Avez-vous une autre adresse e-mail?"
            />
          );
        } else {
          const subscriptions = response;
          const activeSubscriptions = subscriptions.filter(s => s.active);
          const pastSubscriptions = subscriptions.filter(s => !s.active);

          const Subscription = ({ data }) => {
            const intervalMap = {
              day: "jour",
              week: "semaine",
              month: "mois",
              quarter: "trimestre",
              year: "an"
            };
            const currencyMap = {
              eur: "€",
              usd: "$"
            };
            const interval =
              intervalMap[data.product.subscriptionPeriod] || "mois";
            const currency = "€";

            return (
              <List.Item verticalAlign="middle">
                <Image avatar src={subscriptionIcon} />
                <List.Content verticalAlign="middle">
                  {data.product ? data.product.name : data.id}
                  {" - "}
                  <a
                    href={`mailto:support@se-former.app?subject=Terminer mon abonnement&body=${encodeURIComponent(
                      `Bonjour,\n\nJ'aimerais mettre fin à mon abonnement ${
                        data.product.name
                      } à ${data.product.amountInCents /
                        100}€ / mois dès que possible.\nMerci pour votre aide.\n\nCordialement,`
                    )}`}
                    target="_blank"
                    style={{ color: "#A0A0A0" }}
                  >
                    (Annuler)
                  </a>
                </List.Content>
                <List.Content floated="right" verticalAlign="middle">
                  {data.product.amountInCents / 100}
                  {currency + " / " + interval}
                </List.Content>
              </List.Item>
            );
          };

          const subscriptionsByProductName = subscriptions.reduce(
            (acc, val) => {
              acc[val.product.name] = val;
              return acc;
            },
            {}
          );
          const productNames = subscriptions.map(s => s.product.name);
          productNames.sort();

          const Invoice = ({ data }) => {
            const currency = "€";
            const { id, created, amountInCents, paid, url } = data;
            const paidInFull = paid >= amountInCents;

            return (
              <List.Item verticalAlign="middle">
                <List.Content floated="right">
                  {check.nonEmptyString(url) && (
                    <Button as="a" target="_new" href={url}>
                      Voir
                    </Button>
                  )}
                </List.Content>
                <Image
                  avatar
                  src={paidInFull ? invoiceIconBlue : invoiceIconRed}
                />
                <List.Content verticalAlign="middle">
                  {ucfirst(
                    moment(created)
                      .locale("fr")
                      .format("MMMM YYYY")
                  )}{" "}
                  - {amountInCents / 100}
                  {currency}
                </List.Content>
                <Label
                  color={paidInFull ? "green" : "red"}
                  style={{ marginLeft: 20 }}
                >
                  {paidInFull ? "Payée" : "Non payée"}
                </Label>
              </List.Item>
            );
          };

          const InvoiceCategory = ({ product, invoices }) => (
            <Fragment>
              <Card.Header as="h4">{product}</Card.Header>
              <Card.Description>
                <List>
                  {invoices.map(i => (
                    <Invoice key={i.id} data={i} />
                  ))}
                </List>
              </Card.Description>
            </Fragment>
          );

          const activeSubscriptionsElems = activeSubscriptions.map(s => (
            <Subscription key={s.id} data={s} />
          ));
          const pastSubscriptionsElems = pastSubscriptions.map(s => (
            <Subscription key={s.id} data={s} />
          ));

          if (check.nonEmptyArray(activeSubscriptions)) {
            content.push(
              <Fragment key="activeSubscriptions">
                <Card.Header as="h2" style={{ marginTop: 30 }}>
                  Abonnements en cours
                </Card.Header>
                <Card.Description>
                  <List>{activeSubscriptionsElems}</List>
                </Card.Description>
              </Fragment>
            );
          }

          if (check.nonEmptyArray(pastSubscriptions)) {
            content.push(
              <Fragment key="pastSubscriptionsElems">
                <Card.Header as="h2" style={{ marginTop: 30 }}>
                  Abonnements expirés
                </Card.Header>
                <Card.Description>
                  <List>{pastSubscriptionsElems}</List>
                </Card.Description>
              </Fragment>
            );
          }

          if (check.nonEmptyArray(productNames)) {
            content.push(
              <Fragment key="pastSubscriptionsElems">
                <Card.Header as="h2" style={{ marginTop: 30 }}>
                  Factures
                </Card.Header>
                <Card.Description>
                  {productNames.map(p => (
                    <InvoiceCategory
                      key={"invoiceCategory_" + p}
                      product={p}
                      invoices={subscriptionsByProductName[p].invoices}
                    />
                  ))}
                </Card.Description>
              </Fragment>
            );
          }

          /*
          content.push(
            <Fragment key="resultats">
              <Card.Header>Votre e-mail: {email}</Card.Header>
              <Card.Description>
                <Header as="h2"></Header>
                <pre>{JSON.stringify(response, null, 4)}</pre>
              </Card.Description>
            </Fragment>
          );
          */
        }
      } else {
        content.push(
          <MessageDiv
            title="Erreur serveur"
            msg="Une erreur est apparue sur notre serveur. Nous avons été mis au courant. Merci de réessayer plus tard."
          />
        );
      }
    }

    return (
      <Card fluid>
        <Card.Content>{content}</Card.Content>
      </Card>
    );
  }
}

export default withAuthentication(InvoicesWidget);
